import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import SiteImage from "../components/SiteImage"
import "./technology.scss"
import "../fragments/image"
import FormattedText from "../components/FormattedText"

const CheckIcon = () => {
  return (
    <svg
      width="23"
      height="17"
      viewBox="0 0 23 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8507 1.68416L20.4028 0.294704C19.9819 -0.111966 19.3169 -0.0950217 18.9213 0.328594L8.74406 11.0461L3.12934 5.39503C2.71687 4.97989 2.06027 4.97989 1.6478 5.39503L0.309357 6.74213C-0.103119 7.15727 -0.103119 7.81811 0.309357 8.23326L8.07905 16.0532C8.44944 16.426 9.19021 16.6717 9.7963 15.9092L21.8844 3.17529C22.28 2.76015 22.2632 2.09083 21.8507 1.68416Z"
        fill="#ffffff"
      />
    </svg>
  )
}

const CrossIcon = () => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.446943 13.38C0.0919409 13.8031 0.147125 14.4338 0.5702 14.7888L2.10229 16.0744C2.52536 16.4294 3.15612 16.3742 3.51112 15.9511L7.33578 11.3931L11.1614 15.9523C11.5164 16.3753 12.1471 16.4305 12.5702 16.0755L14.1023 14.7899C14.5254 14.4349 14.5805 13.8042 14.2255 13.3811L9.94659 8.28165L14.4385 2.92839C14.7935 2.50531 14.7383 1.87456 14.3153 1.51955L12.7832 0.233979C12.3601 -0.121023 11.7293 -0.0658392 11.3743 0.357235L7.33578 5.1702L3.29816 0.358351C2.94316 -0.0647235 2.3124 -0.119908 1.88932 0.235094L0.357236 1.52067C-0.0658388 1.87567 -0.121023 2.50643 0.233979 2.9295L4.72496 8.28165L0.446943 13.38Z"
        fill="#ffffff44"
      />
    </svg>
  )
}

export default function Technology({
  data: {
    strapiTechnology: {
      seo,
      title,
      description,
      whitePaperButtonText,
      whitePaperDescription,
      whitePaperFile,
      heroBg,
      benefits,
      difference,
      gamingBlockchain,
      comparisonChart,
    },
  },
}) {
  return (
    <Layout showNewsletterCTA>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        jsonLDSchema={seo.jsonLDSchema}
        pathname="technology"
      />
      <section className="section" id="technology-hero">
        <SiteImage className="hero-bg" image={heroBg} alt="" />
        <div className="container">
          <div className="columns">
            <div className="column is-7">
              <h1 className="title is-1">{title}</h1>
              <div className="my-5">
                <FormattedText>{description}</FormattedText>
              </div>
              <div className="has-text-weight-bold my-5">
                {whitePaperDescription}
              </div>
              <a
                className="button is-gradient is-rounded"
                href={whitePaperFile.localFile.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {whitePaperButtonText}
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-medium" id="technology-benefits">
        <div className="container">
          <div className="is-flex is-align-items-center py-6">
            <div className="section-title-decoration mr-3"></div>
            <div className="section-title">{benefits.title}</div>
          </div>
          <div className="columns">
            {benefits.card.map(({ id, title, description, logo }) => (
              <div
                key={id}
                className="column is-flex is-flex-direction-column is-align-items-center"
              >
                <SiteImage image={logo} alt="" />
                <h4 className="title is-4 my-6">{title}</h4>
                <div>{description}</div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="section is-medium" id="technology-difference">
        <div className="container">
          <div className="is-flex is-align-items-center py-6">
            <div className="section-title-decoration mr-3"></div>
            <div className="section-title">{difference.title}</div>
          </div>
          <div className="columns is-gapless is-multiline">
            {difference.card.map(({ id, title, logo, description }) => (
              <div
                className="column is-4 is-flex is-flex-direction-column is-flex-grow-1"
                key={id}
              >
                <div className="card-container is-flex-grow-1">
                  <div className="card-cover my-6">
                    <SiteImage image={logo} />
                    <h5 className="title is-5 mt-4 mx-6 has-text-centered">
                      {title}
                    </h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {comparisonChart && (
        <section className="section is-medium" id="technology-comparison-chart">
          <div className="table-container">
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={{
                td({ children }) {
                  if (children[0] === "\\check") {
                    return (
                      <td>
                        <CheckIcon />
                      </td>
                    )
                  } else if (children[0] === "\\cross") {
                    return (
                      <td>
                        <CrossIcon />
                      </td>
                    )
                  }
                  return <td>{children}</td>
                },
              }}
            >
              {comparisonChart.data.comparisonChart}
            </ReactMarkdown>
          </div>
        </section>
      )}
      <section className="section is-medium" id="technology-blockchain">
        <div className="container">
          <div className="is-flex is-align-items-center py-6">
            <div className="section-title-decoration mr-3"></div>
            <div className="section-title">{gamingBlockchain.title}</div>
          </div>
          <div className="my-3">{gamingBlockchain.description}</div>
          <div className="columns">
            {gamingBlockchain.gamingBlockchainItem.map(
              ({ id, title, description }, i) => {
                const classIndex = (i % 4) + 1
                return (
                  <div className="column is-4" key={id}>
                    <div
                      className={`title-decoration my-5 title-decoration-type-${classIndex}`}
                    />
                    <h5 className={`title is-5 title-type-${classIndex}`}>
                      {title}
                    </h5>
                    <div className="my-4">{description}</div>
                  </div>
                )
              }
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    strapiTechnology {
      seo {
        title
        description
        keywords
        jsonLDSchema
      }
      title
      description
      whitePaperButtonText
      whitePaperDescription
      whitePaperFile {
        localFile {
          url
        }
      }
      heroBg {
        ...StrapiImage
      }
      benefits {
        title
        card {
          id
          title
          description
          logo {
            ...StrapiImage
          }
        }
      }
      difference {
        title
        card {
          id
          title
          logo {
            ...StrapiImage
          }
        }
      }
      gamingBlockchain {
        title
        description
        gamingBlockchainItem {
          id
          title
          description
        }
      }
      comparisonChart {
        data {
          comparisonChart
        }
      }
    }
  }
`
